<template>
  <div>
    <subscription-policy />
  </div>
</template>

<script>
import SubscriptionPolicy from "../components/SubscriptionPolicy/index.vue";
export default {
  components: {
    SubscriptionPolicy,
  },
};
</script>

<style></style>
